import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import axios from 'axios';
import { useEffect, useState, useRef } from "react";
import moment from 'moment';
import Details from "./Details";
import { useDownloadExcel } from 'react-export-table-to-excel';
import Header from "../Header";

export default function List() {
    const base_url = process.env.REACT_APP_BASEURL
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const secret = searchParams.get("k")
    const [list, setList] = useState([])
    const [totalFormula, setTotalFormula] = useState(0)
    const tableRef = useRef(null);
    const [exportData, setExportData] = useState([])

    const getFormula = () => {
        axios.post(base_url + "getForms")
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.status === true && response.data.data.length > 0) {
                        setList(response.data.data)
                    }
                    else {
                        console.log(response)
                    }
                }
                else
                    console.log(response);
            });
    }

    const getTotalFormula = () => {
        axios.post(base_url + "getTotalFormula")
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.data.length > 0) {
                        setTotalFormula(response.data.data[0].Total)
                    }
                    else {
                        console.log(response)
                    }
                }
                else
                    console.log(response);
            });
    }

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'Lebanese Community',
        sheet: 'Forms'
    })

    const getSituation = (e) => {
        if (e === 'C')
            return 'Single'
        else if (e === 'M')
            return 'Married'
        else if (e === 'W')
            return 'widower'
        else if (e === 'D')
            return 'Divorcee'
    }

    const getProfession = (e) => {
        if (e === 'C')
            return 'Commerçant'
        else if (e === 'D')
            return 'Directeur'
        else if (e === 'G')
            return 'Gérant'
        else if (e === 'E')
            return 'Employé(e)'
        else if (e === 'I')
            return 'Ingénieur'
        else if (e === 'A')
            return 'Métiers libres'
    }

    useEffect(() => {
        if (secret != null & secret === process.env.REACT_APP_SECRET) {
            getFormula()
            getTotalFormula()
        }
        else
            navigate('/')
    }, [])

    return (
        <div className="flex flex-col w-full min-h-screen bg-gray">
            <Header />

            <div className="mx-5">
                <div className="w-full shadow bg-white px-10 overflow-y-auto py-8 mt-2">
                    <div className="flex flex-row">
                        <h3 className="font-bold block text-[18px] p-3 text-gray-dark flex-grow">List of Forms</h3>
                        <div className="flex justify-center items-center text-[15px] p-3 italic font-bold text-blue">{totalFormula} submitted form(s)</div>
                        <button type="button" onClick={onDownload} className="p-1 justify-center vertical-center bg-green text-white m-1 text-[16px]">
                            <span className="lg:bg-white flex lg:text-green text-white lg:px-4 lg:py-2 font-bold italic hover:text-white lg:text-[16px] text-sm hover:bg-green">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="lg:w-6 w-4 lg:h-6 h-4 lg:mr-2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                </svg>
                                <span className="lg:block hidden">Export</span></span>
                        </button>
                    </div>
                    <table className="w-full overflow-auto" ref={tableRef}>
                        <thead>
                            <tr className="text-left border-b border-b-gray">
                                <th className="font-bold p-3">#</th>
                                <th className="font-bold p-3">Full Name</th>
                                <th className="font-bold p-3">Mother name</th>
                                <th className="font-bold p-3">DOB</th>
                                <th className="font-bold p-3">Passport No.</th>
                                <th className="font-bold p-3">Profession</th>
                                <th className="font-bold p-3">Company</th>
                                <th className="font-bold p-3">Address</th>
                                <th className="font-bold p-3">Phone</th>
                                <th className="font-bold p-3">Situation</th>
                                <th className="font-bold p-3">Spouse/ Husband</th>
                                <th>Child</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list
                                    ? list.map((x, index) => {
                                        return <tr key={index} className='text-left border-b border-b-gray'>
                                            <td className="p-3">{index + 1}</td>
                                            <td className="p-3">{x.pname} {x.pfather} {x.pfamily}</td>
                                            <td className="p-3">{x.pmother}</td>
                                            <td className="p-3">{moment(x.pbirthday).format('YYYY-MM-DD')}, {x.pbirthdayPlace}</td>
                                            <td className="p-3">{x.ppassport}</td>
                                            <td className="p-3">{getProfession(x.pprofession)}</td>
                                            <td className="p-3">{x.pcompany}</td>
                                            <td className="p-3">{x.paddress}</td>
                                            <td className="p-3">{x.pphone}</td>
                                            <td className="p-3">{getSituation(x.psituation)}</td>
                                            <td className="p-3">{x.pwife ? 'Live in LBM' : 'Not in LBM'}</td>
                                            <td className="p-3">{x.child}</td>
                                            <td>{x.pwife ? <Details data={x} /> : null}</td>
                                        </tr>
                                    })
                                    : null
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}