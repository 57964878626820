import React, { Component, useState, useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import arFlag from "./Assets/img/lebanon.png";
import frFlag from "./Assets/img/france.png";
import { useTranslation } from "react-i18next";
import { useGlobalState, setGlobalState } from './GlobalStates';

function Language(props) {
  const { i18n } = useTranslation();
  const [cookies, setCookie, removeCookie] = useCookies();
  const [language, setLanguage] = useState(frFlag);
  const [showDiv, setShowDiv] = useState('hidden')
  const [direction, setDirection] = useGlobalState('direction')

  function handleCookie(e) {
    setCookie("i18next", e, {
      expires: new Date(Date.now() + 360 * 24 * 60 * 60 * 1000),
    });
    i18n.changeLanguage(e);
    handleChangeLanguage(e);
    setShowDiv('hidden')
    displayLang()
  }

  const handleChangeLanguage = (e) => {
    if (e == "ar") {
      setLanguage(arFlag);
      setDirection('rtl')
    } else {
      setLanguage(frFlag);
      setDirection('ltr')
    }
  };

  const displayLang = () => {
    if (showDiv == '')
      setShowDiv('hidden')
    else
      setShowDiv('')
  }

  useEffect(() => {
    if (cookies.i18next == "ar") setLanguage(arFlag);
    else setLanguage(frFlag);
  });

  return (
    <div className="dropdown p-0 m-">
      <div className="p-0 m-0 bg-white min-w-[100px] hover:cursor-pointer" onClick={() => displayLang()}>
        <div className="w-auto h-40px text-white p-1 m-0 float-right">
          <div className="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto">
            <img src={language} className='lg:w-[30px] w-[40px] lg:h-[30px] h-[40px]' />
          </div>
        </div>
      </div>
      <div className={"flex flex-col mt-10 bg-white absolute right-2 z-50 " + showDiv}>
        <div className="flex flex-row p-2 hover:opacity-60 hover:bg-gray hover:cursor-pointer" onClick={() => handleCookie("ar")}>
          <img src={arFlag} width="30" height="30" />{" "}
          <span className="symbol symbol-20 mr-1"></span> العربية
        </div>
        <div className="flex flex-row p-2 hover:opacity-60 hover:bg-gray hover:cursor-pointer" onClick={() => handleCookie("fr")}>
          <img src={frFlag} width="30" height="30" />
          <span className="symbol symbol-20 mr-1"></span> Français
        </div>
      </div>
    </div>
  );
}

export default Language;
