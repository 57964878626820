
import logo from './Assets/img/logo.jpg';
import Language from './Language';

export default function Header() {

    return (
        <div className='flex flex-row w-full bg-white h-[70px] max-h-[70px]'>
            <div className='flex lg-pl-0 pl-3 lg:justify-center flex-grow'>
                <img src={logo} className='lg:max-w-[100px] max-w-[70px] lg:max-h-[100px] max-h-[70px]' />
            </div>
            <div className='flex p-3'>
                <Language />
            </div>
        </div>
    )
}