import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import List from './Admin/List';
import Formula from './Formula';
import { useCookies } from "react-cookie";
import { useEffect, useState } from 'react';

function App() {
  const [cookie, setCookie] = useCookies();


  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    console.log('Cache cleared')
  };

  useEffect(() => {
    if (cookie.i18next == null)
      setCookie("i18next", "fr", {
        expires: new Date(Date.now() + 360 * 24 * 60 * 60 * 1000),
      });
    else
      setCookie("i18next", cookie.i18next, {
        expires: new Date(Date.now() + 360 * 24 * 60 * 60 * 1000),
      });

    if (cookie.tkn === null || cookie.tkn === undefined) {
      setCookie('tkn', process.env.REACT_APP_TOKEN)
      clearCacheData()
    }
    if (cookie.tkn != process.env.REACT_APP_TOKEN) {
      setCookie('tkn', process.env.REACT_APP_TOKEN, {
        expires: new Date(Date.now() + 360 * 24 * 60 * 60 * 1000),
      })
      clearCacheData()
    }
  }, []);
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/" element={<Formula />} />
          <Route exact path="/Admin" element={<List />} />
        </Routes>
      </Router>
    </div >

  );
}

export default App;
