import './App.css';
import "./i18n";
import { useCookies } from "react-cookie";
import { useEffect, useState } from 'react';
import { useGlobalState } from './GlobalStates';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import arFlag from './Assets/img/lebanon.png'
import frFlag from './Assets/img/france.png'
import Header from './Header';

function Formula() {
  const { t, i18n } = useTranslation();
  const [cookie, setCookie] = useCookies();
  const [direction, setDirection] = useGlobalState('direction')
  const [showProg, setShowProg] = useState(false)
  const [showLang, setShowLang] = useState('')
  const [showMarried, setShowMarried] = useState(false)
  const [showMarriedLbm, setShowMarriedLbm] = useState(false)
  const [showOther, setShowOther] = useState('hidden')
  const baseurl = process.env.REACT_APP_BASEURL

  const [name, setName] = useState('')
  const [father, setFather] = useState('')
  const [family, setFamily] = useState('')
  const [mother, setMother] = useState('')
  const [dob, setDob] = useState('')
  const [bLocation, setBLocation] = useState('')
  const [passport, setPassport] = useState('')
  const [profession, setProfession] = useState('')
  const [company, setCompany] = useState('')
  const [address, setAddress] = useState('')
  const [phone, setphone] = useState('')
  const [email, setEmail] = useState('')
  const [situation, setSituation] = useState('')
  const [wife, setWife] = useState('')
  const [wDob, setWdob] = useState('')
  const [wNationality, setWNationality] = useState('')
  const [wDLocation, setWDLocation] = useState('')
  const [lbm, setLbm] = useState('')
  const [professionOther, setProfessionOther] = useState('')

  // Errors
  const [errName, setErrName] = useState('border-gray')
  const [errFather, setErrFather] = useState('border-gray')
  const [errFamily, setErrFamily] = useState('border-gray')
  const [errMother, setErrMother] = useState('border-gray')
  const [errDob, setErrDob] = useState('border-gray')
  const [errBLocation, setErrBLocation] = useState('border-gray')
  const [errPassport, setErrPassport] = useState('border-gray')
  const [errProfession, setErrProfession] = useState('border-gray')
  const [errCompany, setErrCompany] = useState('border-gray')
  const [errAddress, setErrAddress] = useState('border-gray')
  const [errPhone, setErrphone] = useState('border-gray')
  const [errEmail, setErrEmail] = useState('border-gray')
  const [errSituation, setErrSituation] = useState('border-gray')
  const [errWife, setErrWife] = useState('border-gray')
  const [errWDob, setErrWdob] = useState('border-gray')
  const [errWNationality, setErrWNationality] = useState('border-gray')
  const [errWDLocation, setErrWDLocation] = useState('border-gray')
  const [errLbm, setErrLbm] = useState('')
  const [errProfessionOther, setErrProfessionOther] = useState('border-gray')

  const clearState = () => {
    setName('')
    setFather('')
    setFamily('')
    setMother('')
    setDob('')
    setBLocation('')
    setPassport('')
    setProfession('')
    setCompany('')
    setAddress('')
    setphone('')
    setEmail('')
    setSituation('')
    setWife('')
    setWdob('')
    setWNationality('')
    setWDLocation('')
    setRowsData([])
    setLbm('')
    setProfessionOther('')
    setShowOther('hidden')

    const sit1 = document.getElementById('1');
    sit1.checked = false
    const sit2 = document.getElementById('2');
    sit2.checked = false
    const sit3 = document.getElementById('3');
    sit3.checked = false
    const sit4 = document.getElementById('4');
    sit4.checked = false

    setShowMarried(false)
    setShowMarriedLbm(false)
  }

  useEffect(() => {
    if (cookie.i18next == 'ar')
      setDirection('rtl')
    else
      setDirection('ltr')
  }, []);

  const handleSubmit = (e) => {
    e.currentTarget.disabled = true;
    let valid = true

    if (name == '') {
      setErrName('border-red')
      valid = false
    }
    if (father === '') {
      setErrFather('border-red')
      valid = false
    }
    if (family === '') {
      setErrFamily('border border-red')
      valid = false
    }
    if (mother === '') {
      setErrMother('border border-red')
      valid = false
    }
    if (dob === '') {
      setErrDob('border border-red')
      valid = false
    }
    if (bLocation === '') {
      setErrBLocation('border border-red')
      valid = false
    }
    if (passport === '') {
      setErrPassport('border border-red')
      valid = false
    }
    if (profession == '') {
      setErrProfession('border border-red')
      valid = false
    }
    if (profession === 'A') {
      if (professionOther === '') {
        setErrProfessionOther('border border-red')
        valid = false
      }
    }
    if (company === '') {
      setErrCompany('border border-red')
      valid = false
    }
    if (address === '') {
      setErrAddress('border border-red')
      valid = false
    }
    if (phone === '') {
      setErrphone('border border-red')
      valid = false
    }
    if (situation === '') {
      setErrSituation('border border-red')
      valid = false
    }
    if (situation != 'C' && situation != '' && situation != 'V') {
      if (lbm === '') {
        setErrLbm('border border-red')
        valid = false
      }
      else if (showMarried) {
        if (wife === '') {
          setErrWife('border border-red')
          valid = false
        }
        if (wDob === '') {
          setErrWdob('border border-red')
          valid = false
        }
        if (wNationality === '') {
          setErrWNationality('border border-red')
          valid = false
        }
        if (wDLocation === '') {
          setErrWDLocation('border border-red')
          valid = false
        }
      }
    }


    let d = false
    let p = "top-right"
    if (direction === "rtl") {
      d = true
      p = "top-left"
    }

    if (valid) {
      let dateNow = new Date()
      dateNow = moment(dateNow).format("YYYY-MM-DD")

      axios.post(baseurl, {
        name: name,
        father: father,
        family: family,
        mother: mother,
        birthday: dob,
        birthdayPlace: bLocation,
        passport: passport,
        profession: profession,
        company: company,
        address: address,
        phone: phone,
        email: email,
        situation: situation,
        wife: wife,
        wifeBirthday: wDob,
        wifeNationality: wNationality,
        wifeBirthdayPlace: wDLocation,
        createdDate: dateNow,
        child: rowsData,
        professionOther: professionOther
      })
        .then((response) => {
          if (response.status === 200) {
            if (response.data[0].status === true) {
              clearState();
              toast.success(t("main.msg"), {
                position: p,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                rtl: d
              });
            }
            else {
              toast.error(t(response.data[0].data), {
                position: p,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                rtl: d
              });
              console.log(response.data)
            }
          }
          else
            console.log(response)
        })
    }
    else {
      toast.error(t("main.msg2"), {
        position: p,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        rtl: d
      });
    }
    setShowProg(false)
    e.currentTarget.disabled = false;
  }

  const onSiteChanged = (e) => {
    setSituation(e.currentTarget.value)

    if (e.currentTarget.value != 'C' && e.currentTarget.value != 'V')
      setShowMarriedLbm(true)
    else
      setShowMarriedLbm(false)
  }

  const onLbmChanged = (e) => {
    if (e.currentTarget.value === 'Y') {
      setShowMarried(true)
      setLbm('Y')
    }
    else {
      setShowMarried(false)
      setLbm('N')
    }
  }

  const [rowsData, setRowsData] = useState([]);

  const addTableRows = () => {

    const rowsInput = {
      name: '',
      sex: '',
      dob: '',
      place: ''
    }
    setRowsData([...rowsData, rowsInput])
  }

  const handleChange = (index, evnt) => {

    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  }

  const [language, setLanguage] = useState(frFlag);

  const changeLanguage = (e) => {
    setShowProg(true)
    setCookie("i18next", e, {
      expires: new Date(Date.now() + 360 * 24 * 60 * 60 * 1000),
    });
    i18n.changeLanguage(e);
    if (e == "ar") {
      setLanguage(arFlag);
      setDirection('rtl')
    } else {
      setLanguage(frFlag);
      setDirection('ltr')
    }
    setShowLang('hidden')
    setShowProg(false)
  }

  const professionChanged = (e) => {
    setErrProfession('border-gray');
    setProfession(e.target.value);
    if (e.target.value === 'A')
      setShowOther('')
    else {
      setShowOther('hidden')
      setProfessionOther('')
    }
  }

  return (
    <div className="flex flex-col bg-gray w-full min-h-screen relative">
      <div className={'absolute flex lg:mt-0 -mt-96 bg-transparent inset-0 z-50 place-content-center ' + showLang}>
        <div role="status" className='p-2 flex justify-center items-center place-content-center'>
          <div className='flex flex-col bg-white p-5'>
            <h1 className='p-1 uppercase font-bold'>Choisissez votre langue/ اختر لغتك</h1>
            <hr className='bg-gray' />
            <div className='flex cursor-pointer m-2' onClick={() => changeLanguage('ar')}>
              <img src={arFlag} className='p-1' width={40} />
              <label className='p-1 cursor-pointer'>العربية</label>
            </div>
            <hr className='text-gray' />
            <div className='flex cursor-pointer m-2' onClick={() => changeLanguage('fr')}>
              <img src={frFlag} className='p-1' width={40} />
              <label className='p-1 cursor-pointer'>Français</label>
            </div>
          </div>
        </div>
      </div>
      <div className={'w-screen h-screen fixed opacity-50 inset-0 z-40 bg-gray ' + showLang}></div>

      {
        showProg
          ? <>
            <div className={'absolute flex bg-transparent inset-0 z-50 m-auto justify-center items-center place-content-center '}>
              <div role="status" className='p-2 flex justify-center items-center place-content-center'>
                <div className='flex flex-col p-5'>
                  <svg className="inline mr-2 w-8 h-8 text-gray animate-spin dark:text-gray fill-green" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                  </svg>
                  <span className='sr-only'>Saving...</span>
                </div>
              </div>
            </div>
            <div className={'w-screen h-screen fixed opacity-50 inset-0 z-40 bg-gray '}></div>
          </>
          : null
      }


      <ToastContainer />

      <Header />

      <div className='flex flex-col m-2 bg-white shadow drop-shadow p-5 lg:px-10 mx-auto' dir={direction}>
        <div className='flex lg:flex-row flex-col mb-2 w-full justify-center my-3 mb-5'>
          <h1 className='uppercase font-bold text-3xl'>{t("main.title")}</h1>
        </div>

        <div className='flex lg:flex-row flex-col mb-2 w-full justify-between'>
          <div className='flex-1 inline-flex flex lg:flex-row flex-col'>
            <label className='p-1 whitespace-nowrap'>{t("main.firstName")} <span className='text-red'>*</span></label>
            <input onChange={(e) => { setErrName('border-gray'); setName(e.target.value) }} value={name} className={'border mx-2 p-1 px-3 outline-none focus:border-gray-dark ' + errName} />
          </div>
          <div className='flex-1 inline-flex flex  lg:flex-row flex-col'>
            <label className='p-1 whitespace-nowrap'>{t("main.father")} <span className='text-red'>*</span></label>
            <input onChange={(e) => { setErrFather('border-gray'); setFather(e.target.value) }} value={father} className={'border mx-2 p-1 px-3 outline-none focus:border-gray-dark ' + errFather} />
          </div>
          <div className='flex-1 inline-flex flex  lg:flex-row flex-col'>
            <label className='p-1 whitespace-nowrap'>{t("main.name")} <span className='text-red'>*</span></label>
            <input onChange={(e) => { setErrFamily('border-gray'); setFamily(e.target.value) }} value={family} className={'border mx-2 p-1 px-3 outline-none focus:border-gray-dark ' + errFamily} />
          </div>
        </div>

        <div className='flex lg:flex-row flex-col mb-2 w-full justify-between'>
          <div className='flex-1 inline-flex lg:flex-row flex-col'>
            <label className='p-1 whitespace-nowrap'>{t("main.motherFullName")} <span className='text-red'>*</span></label>
            <input onChange={(e) => { setErrMother('border-gray'); setMother(e.target.value) }} value={mother} className={'border mx-2 p-1 px-3 outline-none focus:border-gray-dark lg:w-full ' + errMother} />
          </div>
        </div>

        <div className='flex lg:flex-row flex-col mb-2 w-full '>
          <div className='flex-1 inline-flex flex lg:flex-row flex-col'>
            <label className='p-1 whitespace-nowrap'>{t("main.birthday")} <span className='text-red'>*</span></label>
            <input onChange={(e) => { setErrDob('border-gray'); setDob(e.target.value) }} value={dob} type={'date'} className={'border mx-2 p-1 px-3 outline-none focus:border-gray-dark lg:w-full ' + errDob} />
          </div>
          <div className='flex-1 inline-flex flex  lg:flex-row flex-col'>
            <label className='p-1 whitespace-nowrap'>{t("main.bLocation")} <span className='text-red'>*</span></label>
            <input onChange={(e) => { setErrBLocation('border-gray'); setBLocation(e.target.value) }} value={bLocation} className={'border mx-2 p-1 px-3 outline-none focus:border-gray-dark lg:w-full ' + errBLocation} />
          </div>
        </div>

        <div className='flex lg:flex-row flex-col mb-2 w-full '>
          <div className='flex-1 inline-flex flex  lg:flex-row flex-col'>
            <label className='p-1 whitespace-nowrap'>{t("main.passport")} <span className='text-red'>*</span></label>
            <input onChange={(e) => { setErrPassport('border-gray'); setPassport(e.target.value) }} value={passport} className={'border mx-2 p-1 px-3 outline-none focus:border-gray-dark lg:w-full ' + errPassport} />
          </div>
        </div>

        <div className='flex lg:flex-row flex-col mb-2 w-full'>
          <div className='flex-1 inline-flex flex  lg:flex-row flex-col'>
            <label className='p-1 whitespace-nowrap'>{t("main.profession")} <span className='text-red'>*</span></label>
            <select className={'border mx-2 p-1 px-3 outline-none focus:border-gray-dark lg:w-full ' + errProfession} onChange={(e) => { professionChanged(e) }} value={profession}>
              <option value={''}></option>
              <option value={'C'}>{t('main.profession1')}</option>
              <option value={'D'}>{t('main.profession3')}</option>
              <option value={'G'}>{t('main.profession2')}</option>
              <option value={'E'}>{t('main.profession6')}</option>
              <option value={'I'}>{t('main.profession4')}</option>
              <option value={'A'}>{t('main.profession5')}</option>
            </select>
            <input onChange={(e) => { setErrProfessionOther('border-gray'); setProfessionOther(e.target.value) }} value={professionOther} className={'border mx-2 p-1 px-3 outline-none focus:border-gray-dark lg:w-full ' + showOther + ' ' + errProfessionOther} />
          </div>
          <div className='flex-1 inline-flex flex  lg:flex-row flex-col'>
            <label className='p-1 whitespace-nowrap'>{t("main.society")} <span className='text-red'>*</span></label>
            <input onChange={(e) => { setErrCompany('border-gray'); setCompany(e.target.value) }} value={company} className={'border mx-2 p-1 px-3 outline-none focus:border-gray-dark lg:w-full ' + errCompany} />
          </div>
        </div>

        <div className='flex lg:flex-row flex-col mb-2 w-full '>
          <div className='flex-1 inline-flex flex  lg:flex-row flex-col'>
            <label className='p-1 whitespace-nowrap'>{t("main.adress")} <span className='text-red'>*</span></label>
            <textarea onChange={(e) => { setErrAddress('border-gray'); setAddress(e.target.value) }} value={address} className={'border mx-2 p-1 px-3 outline-none focus:border-gray-dark lg:w-full ' + errAddress} />
          </div>
        </div>

        <div className='flex lg:flex-row flex-col mb-2 w-full'>
          <div className='flex-1 inline-flex flex lg:flex-row flex-col'>
            <label className='p-1 whitespace-nowrap'>{t("main.phone")} <span className='text-red'>*</span></label>
            <input onChange={(e) => { setErrphone('border-gray'); setphone(e.target.value) }} value={phone} className={'border mx-2 p-1 px-3 outline-none focus:border-gray-dark lg:w-full ' + errPhone} />
          </div>
          <div className='flex-1 inline-flex flex w-full lg:flex-row flex-col'>
            <label className='p-1 whitespace-nowrap'>{t("main.mail")}</label>
            <input onChange={(e) => { setErrEmail('border-gray'); setEmail(e.target.value) }} value={email} className={'border mx-2 p-1 px-3 outline-none focus:border-gray-dark lg:w-full ' + errEmail} />
          </div>
        </div>

        <div className='flex lg:flex-row flex-col mb-2 w-full'>
          <div className='flex-1 inline-flex flex lg:flex-row flex-col w-full lg:flex-row flex-col'>
            <label className={'p-1 whitespace-nowrap ' + errSituation}>{t("main.familySituation")} <span className='text-red'>*</span></label>
            <div className='flex inline-flex'>
              <input id='1' type={'radio'} name='situation' value={'C'} onChange={(e) => { setErrSituation(''); onSiteChanged(e) }} className='cursor-pointer border border-gray mx-2 p-1 px-3 outline-none focus:border-gray-dark w-[20px]' />
              <label htmlFor='1' className='p-1 cursor-pointer'>{t("main.single")}</label>
            </div>
            <div className='flex inline-flex'>
              <input id='2' type={'radio'} name='situation' value={'M'} onChange={(e) => { setErrSituation(''); onSiteChanged(e) }} className='cursor-pointer border border-gray mx-2 p-1 px-3 outline-none focus:border-gray-dark w-[20px]' />
              <label htmlFor='2' className='p-1 cursor-pointer'>{t("main.married")}</label>
            </div>
            <div className='flex inline-flex'>
              <input id='3' type={'radio'} name='situation' value={'D'} onChange={(e) => { setErrSituation(''); onSiteChanged(e) }} className='cursor-pointer border border-gray mx-2 p-1 px-3 outline-none focus:border-gray-dark w-[20px]' />
              <label htmlFor='3' className='p-1 cursor-pointer'>{t("main.divorcee")}</label>
            </div>
            <div className='flex inline-flex'>
              <input id='4' type={'radio'} name='situation' value={'V'} onChange={(e) => { setErrSituation(''); onSiteChanged(e) }} className='cursor-pointer border border-gray mx-2 p-1 px-3 outline-none focus:border-gray-dark w-[20px]' />
              <label htmlFor='4' className='p-1 cursor-pointer'>{t("main.widower")}</label>
            </div>
          </div>
        </div>
        {
          showMarriedLbm
            ? <div className='flex lg:flex-row flex-col mb-2 w-full'>
              <div className='flex-1 inline-flex flex w-full lg:flex-row flex-col'>
                <label className={'p-1 whitespace-nowrap ' + errLbm}>{t("main.spouseInLbm")} <span className='text-red'>*</span></label>
                <div className='flex inline-flex'>
                  <input id='inLbm1' type={'radio'} name='inLbm' value={'Y'} onChange={(e) => { setErrLbm(''); onLbmChanged(e) }} className='cursor-pointer border border-gray mx-2 p-1 px-3 outline-none focus:border-gray-dark w-[20px]' />
                  <label htmlFor='inLbm1' className='p-1 cursor-pointer'>{t("main.y")}</label>
                </div>
                <div className='flex inline-flex'>
                  <input id='inLbm2' type={'radio'} name='inLbm' value={'N'} onChange={(e) => { setErrLbm(''); onLbmChanged(e) }} className='cursor-pointer border border-gray mx-2 p-1 px-3 outline-none focus:border-gray-dark w-[20px]' />
                  <label htmlFor='inLbm2' className='p-1 cursor-pointer'>{t("main.n")}</label>
                </div>
              </div>
            </div>
            : null
        }
        {
          showMarried ? <>
            <div className='flex lg:flex-row flex-col mb-2 w-full'>
              <div className='flex-1 inline-flex flex w-full lg:flex-row flex-col'>
                <label className='p-1 whitespace-nowrap'>{t("main.spouseFullName")} <span className='text-red'>*</span></label>
                <input onChange={(e) => { setErrWife('border-gray'); setWife(e.target.value) }} value={wife} className={'border mx-2 p-1 px-3 outline-none focus:border-gray-dark w-full ' + errWife} />
              </div>
            </div>

            <div className='flex lg:flex-row flex-col mb-2 lg:w-full'>
              <div className='flex-1 inline-flex flex w-full lg:flex-row flex-col'>
                <label className='p-1 whitespace-nowrap'>{t("main.nationality")} <span className='text-red'>*</span></label>
                <input onChange={(e) => { setErrWNationality('border-gray'); setWNationality(e.target.value) }} value={wNationality} className={'border mx-2 p-1 px-3 outline-none focus:border-gray-dark w-full ' + errWNationality} />
              </div>
              <div className='flex-1 inline-flex flex w-full lg:flex-row flex-col'>
                <label className='p-1 whitespace-nowrap'>{t("main.birthday")} <span className='text-red'>*</span></label>
                <input onChange={(e) => { setErrWdob('border-gray'); setWdob(e.target.value) }} value={wDob} type={'date'} className={'border mx-2 p-1 px-3 outline-none focus:border-gray-dark w-full ' + errWDob} />
              </div>
              <div className='flex-1 inline-flex flex w-full lg:flex-row flex-col'>
                <label className='p-1 whitespace-nowrap'>{t("main.bLocation")} <span className='text-red'>*</span></label>
                <input onChange={(e) => { setErrWDLocation('border-gray'); setWDLocation(e.target.value) }} value={wDLocation} className={'border mx-2 p-1 px-3 outline-none focus:border-gray-dark w-full ' + errWDLocation} />
              </div>
            </div>

            <div className='flex lg:flex-row flex-col flex-col w-full m-0'>
              <div className='w-full'>
                <h2 className='font-bold uppercase underline'>{t("main.children")}</h2>
                <button className='bg-green text-white p-1 rounded-full cursor-pointer text-[16px] mx-2 my-1' onClick={() => addTableRows()}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                  </svg>
                </button>
                <table className={'table-auto overflow-x-auto w-full mt-1 ' + (direction === 'ltr' ? 'text-left' : 'text-right')}>
                  <thead>
                    <tr>
                      <th className='p-2 break-word'>{t("main.firstName")}</th>
                      <th className='p-2 break-word'>{t("main.sexe")}</th>
                      <th className='p-2 break-word'>{t("main.birthday")}</th>
                      <th className='p-2 break-word'>{t("main.bLocation")}</th>
                    </tr>
                  </thead>
                  <tbody className='lg:p-1'>
                    {
                      rowsData
                        ? rowsData.map((x, index) => {
                          const { name, sex, dob, place } = x;
                          return <tr key={index}>
                            <td className=''><input className='border border-gray lg:mx-2 p-1 px-3 outline-none focus:border-gray-dark w-full' value={name} onChange={(evnt) => (handleChange(index, evnt))} name="name" /></td>
                            <td className=''>
                              <select className='border border-gray lg:mx-2 p-1 px-3 outline-none focus:border-gray-dark w-full' value={sex} onChange={(evnt) => (handleChange(index, evnt))} name="sex">
                                <option value={-1}></option>
                                <option value={'M'}>{t("main.male")}</option>
                                <option value={'F'}>{t("main.female")}</option>
                              </select>
                            </td>
                            <td className=''><input type={'date'} className='border border-gray lg:mx-2 break-word lg:text-md p-1 px-3 outline-none focus:border-gray-dark lg:w-full w-[100px]' value={dob} onChange={(evnt) => (handleChange(index, evnt))} name="dob" /></td>
                            <td className=''><input className='border border-gray lg:mx-2 p-1 px-3 outline-none focus:border-gray-dark w-full' value={place} onChange={(evnt) => (handleChange(index, evnt))} name="place" /></td>
                          </tr>
                        })
                        : null
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </>
            : null
        }

        <div className='flex flex-col mb-2 w-full items-end mt-3'>
          <button className='bg-green text-white max-w-[100px] min-w-[100px] p-2 hover:opacity-60 shadow drop-shadow shadow-green' onClick={(e) => { setShowProg(true); handleSubmit(e) }}>{t("main.submit")}</button>
        </div>
      </div>



    </div >
  );
}

export default Formula;
