import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment'

export default function Details(props) {
    const data = props.data;
    const [showModal, setShowModal] = useState(false);
    const [list, setList] = useState([])
    const [parentId, setparentId] = useState(data.pid ? data.pid : '')
    const base_url = process.env.REACT_APP_BASEURL

    const getDetails = () => {
        if (parentId != null) {
            axios.post(base_url + "getChild",
                {
                    parentId: data.pid,
                })
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.data.length > 0) {
                            setList(response.data.data)
                        }
                        else {

                        }
                    }
                    else
                        console.log(response);
                });
        }
    }

    const getSituation = (e) => {
        if (e === 'C')
            return 'Single'
        else if (e === 'M')
            return 'Married'
        else if (e === 'W')
            return 'widower'
        else if (e === 'D')
            return 'Divorcee'
    }

    useEffect(() => {
        getDetails()
    }, [])
    return (
        <>
            <button className="bg-blue p-2 rounded mr-1 bg-green" onClick={() => { setShowModal(true); getDetails() }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-white">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                </svg>
            </button>
            {
                showModal ? (
                    <>
                        <div className="fixed bg-transparent justify-center items-center place-content-center">
                            <div className="fixed flex justify-center place-items-center p-2 inset-0">
                                <div className="relative w-full max-w-4xl p-4 mx-auto bg-white rounded-md shadow-lg z-50">
                                    <div className="mt-3 flex flex-col">
                                        <div className="flex flex-row items-center mx-auto bg-red-100 rounded-full">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
                                            </svg>
                                            <h4 className="text-lg text-gray-800 ml-2"><span className="font-bold">{data.pname} {data.pfather} {data.pfamily} {props.customerName}</span></h4>
                                        </div>
                                        {/* <div className="flex mx-5 p-2 border-gray shadow my-1">
                                            <span className="font-bold">Situation: </span>
                                            <span className="pl-1">{getSituation(data.psituation)}</span>
                                        </div> */}
                                        <div className="flex flex-col mx-5 border-gray shadow my-2">
                                            <div className="flex  p-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                                                </svg>

                                                <h2 className="font-bold italic ml-2  text-gray-dark">Husband/Wife Details</h2>
                                            </div>
                                            <hr className="text-gray mb-2" />
                                            <div className="">
                                                <label className="font-bold mr-2">Name:</label>
                                                <span>{data.pwife}</span>
                                            </div>
                                            <div className="">
                                                <label className="font-bold mr-2">Birthday:</label>
                                                <span>{data.pwifeBirthday}, {data.pwifeBirthdayPlace}</span>
                                            </div>
                                            <div className="">
                                                <label className="font-bold mr-2">Nationality:</label>
                                                <span>{data.pwifeNationality}</span>
                                            </div>
                                        </div>
                                        <div className="flex flex-col mb-4 mx-5 my-1 shadow">
                                            <div className="flex p-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                                                </svg>
                                                <h2 className="font-bold italic ml-2 text-gray-dark">Children(s) Details</h2>
                                            </div>
                                            <hr className="text-gray mb-2" />
                                            <div className="w-full max-h-[300px] overflow-y-auto px-5">
                                                <table className="w-full">
                                                    <thead>
                                                        <tr className="border-b border-gray p-3">
                                                            <th className="p-2">#</th>
                                                            <th className="p-2 ">Child</th>
                                                            <th className="p-2 ">Sex</th>
                                                            <th className="p-2">Birthday</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            list
                                                                ? list.map((x, index) => {
                                                                    return <tr key={index}>
                                                                        <td className="p-2 border-b border-gray">{index + 1}</td>
                                                                        <td className="p-2 border-b border-gray">{x.name}</td>
                                                                        <td className="p-2 border-b border-gray">{x.sex}</td>
                                                                        <td className="p-2 border-b border-gray">{moment(x.birthday).format('YYYY-MM-DD')}, {x.birthdayPlace}</td>
                                                                    </tr>
                                                                })
                                                                : null
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            {
                                                list.length == 0
                                                    ? <div className="flex flex-grow w-100 justify-center italic mt-2 text-[#AEAEAE] text-sm">No data to show</div>
                                                    : null
                                            }
                                        </div>
                                        <div className="items-center justify-end gap-2 mt-3 sm:flex">
                                            <button className="mt-2 px-3 py-2 bg-red text-white outline-none hover:opacity-80" onClick={() => setShowModal(false)} >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="fixed opacity-50 inset-0 z-20 bg-gray" onClick={() => setShowModal(false)}>
                                </div>
                            </div>

                        </div>
                    </>
                ) : null
            }
        </>
    );
}